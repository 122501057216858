var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('CoreElementWrapper',{class:_vm.dashboard
      ? 'max-xs:-mt-3'
      : _vm.listDivider
        ? 'list-divider-lg border-theme'
        : '',attrs:{"wrap-top":"","wrap":"","remove-buttons":"","large":_vm.sa,"loading":!_vm.article,"show-left":false},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('ArticleThumbnail',{class:_vm.dashboard ? 'max-xs:hidden' : '',attrs:{"article":_vm.article,"width":420,"url":_vm.articleUrl,"django":_vm.django}})]},proxy:true},{key:"line-1",fn:function(){return [_c('LoadingElement',{attrs:{"type":_vm.SaLinkOrDjango,"component-props":{
        href: _vm.articleUrl,
        django: _vm.django,
      },"loading-classes":"w-full","object":_vm.article}},[_vm._v("\n      "+_vm._s(_vm.article?.title ?? '')+"\n    ")])]},proxy:true},{key:"line-2",fn:function(){return [_c('div',{staticClass:"space-y-4"},[_c('LoadingElement',{staticClass:"text-gray-metadata",attrs:{"loading-classes":"w-2/3","object":_vm.article}},[_c('span',[_vm._v(_vm._s(_vm.articleDate))]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.speakerName),expression:"speakerName"}]},[_vm._v(" | ")]),_vm._v(" "),(_vm.speakerUrl)?_c('SaLinkOrHref',{attrs:{"url":_vm.speakerUrl,"is-to":!_vm.django}},[_vm._v(_vm._s(_vm.speakerName))]):(_vm.speakerName)?_c('span',[_vm._v(_vm._s(_vm.speakerName))]):_vm._e(),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.category),expression:"category"}]},[_vm._v(" • ")]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.category),expression:"category"}],staticClass:"italic"},[_vm._v(_vm._s(_vm.category))]),_vm._v(" "),(_vm.commentCount)?_c('InlineIcon',{attrs:{"icon":"comment"}},[_vm._v("\n          "+_vm._s(_vm.commentCount)+"\n        ")]):_vm._e()],1),_vm._v(" "),_c('LoadingElement',{directives:[{name:"show",rawName:"v-show",value:(!_vm.article || _vm.article.preview),expression:"!article || article.preview"}],staticClass:"line-clamp-2",attrs:{"object":_vm.article,"text-height":false,"loading-classes":"w-full h-2em"}},[_vm._v("\n        "+_vm._s(_vm.preview)+"\n      ")])],1)]},proxy:true}])},[_vm._v(" "),_vm._v(" "),_vm._v(" "),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }